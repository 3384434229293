<template>
	<div class="List">
		<van-nav-bar
			:title="`${isEdit ? '编辑' : '新增'}业务员`"
			left-arrow
			@click-left="onClickLeft"
		>
			<!-- <template #right>
        <UserIcon />
      </template> -->
		</van-nav-bar>
		<van-notice-bar text="新增业务员后，业务员可通过手机号进行登录" />
		<van-form validate-first @submit="onSubmit">
			<van-field
				v-model="formdate.name"
				name="业务员名称"
				label="业务员名称"
				maxlength="10"
				:disabled="isEdit"
				placeholder="请输入业务员名称"
				:rules="[{ required: true, message: '请填写业务员名称' }]"
			/>
			<van-field
				v-model="formdate.certNo"
				name="身份证号"
				label="身份证号"
				:disabled="isEdit && !editCertNo"
				placeholder="请输入业务员身份证号"
				:rules="[
					{ required: true, message: '请输入业务员身份证号' },
					{ pattern: idCodeReg, message: '身份证号格式错误！' }
				]"
			/>
			<van-field
				v-model="formdate.mobile"
				name="业务员账号"
				label="手机号"
				:disabled="isEdit"
				placeholder="请输入业务员手机号"
				:rules="[
					{ required: true, message: '请输入业务员手机号' },
					{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' }
				]"
			/>
			<van-field
				v-model="formdate.jobDesc"
				name="岗位"
				label="岗位"
				maxlength="20"
				:disabled="isEdit"
				placeholder="请输入业务员岗位"
				readonly
				:rules="[{ required: true, message: '请填写业务员岗位' }]"
			/>

			<van-field
				required
				name="assignFlag"
				label="是否派单："
				class="form-no-boder"
				:rules="[{ required: true, message: '请选择' }]"
			>
				<template #input>
					<van-radio-group v-model="formdate.assignFlag" direction="horizontal">
						<van-radio name="1">是</van-radio>
						<van-radio name="2">否</van-radio>
					</van-radio-group>
				</template>
			</van-field>
			<div style="margin: 16px">
				<van-button round block type="info" native-type="submit"
					>提交</van-button
				>
			</div>
		</van-form>
	</div>
</template>
<script>
import Vue from "vue";
import Api from "@/api/user";
import { Form, Field, Toast, NoticeBar } from "vant";
import { idCodeReg } from "@/plugins/common";
Vue.use(Form);
Vue.use(Field);
Vue.use(NoticeBar);
export default {
	data() {
		return {
			idCodeReg,
			userBaseInfo: {},
			isEdit: false,
			editCertNo: false,
			formdate: {
				name: "",
				certNo: "",
				mobile: "",
				job: "1",
				jobDesc: "家访人员",
				assignFlag: null
			}
		};
	},
	created() {
		this.isEdit = !!this.$route.query?.onedit;
		if (this.isEdit) {
			const jsonstr = window.sessionStorage.getItem("_edit_sale_item_data");
			const data = JSON.parse(jsonstr || "{}");
			for (let k in this.formdate) {
				let v = data[k];
				if (k == "assignFlag") v = data[k] ? "1" : "2";
				this.$set(this.formdate, k, v);
			}
			// 进入页面没有身份证的时候，允许身份证可编辑
			if (!this.formdate.certNo) this.editCertNo = true;

			this.$set(this.formdate, "operatorId", data.operatorId);
		}
	},
	async mounted() {
		const userBaseInfo = await Api.baseInfo();
		userBaseInfo.success && (this.userBaseInfo = userBaseInfo.data);
	},
	methods: {
		async onSubmit() {
			await this.operatorRegister();
		},
		// 员工注册
		async operatorRegister() {
			if (this.sbumiting) return;

			try {
				this.sbumiting = true;
				this.formdate.orgNo = this.userBaseInfo.resOperatorDTO.orgNo; // 机构编号
				const requestFn = this.isEdit
					? Api.operatorUpdateAssignFlag
					: Api.operatorRegister;
				let params = {};
				if (this.isEdit) {
					const assignFlag = this.formdate.assignFlag || 2;
					params = {
						assignFlag: assignFlag == 1,
						operatorId: this.formdate.operatorId
					};
				} else {
					params = this.formdate;
				}
				params.assignFlag = params.assignFlag == 1;
				const result = await requestFn(params);
				if (result.success) {
					Toast.success({
						message: result.message
					});
					this.formdate.name = "";
					this.formdate.certNo = "";
					this.formdate.mobile = "";
					this.$router.back();
				}
			} catch (error) {
				Toast.fail(error);
			} finally {
				this.sbumiting = false;
			}
		}
	}
};
</script>
