/**
 * 字符串格式化
 * @param {String} str  /api/delete/{projectId}/{customerId}
 * @param {Array|Object} data {projectId: 1, customerId: 2}
 * @param {Boolean} 是否在data中删除注入的值
 *
 * @returns  /api/delete/1/2
 */
export const stringInject = (str, data, removeInjected = true) => {
  if (typeof str === "string" && data instanceof Array) {
    let injectedIndices = new Set();
    let result = str.replace(/({\d})/g, function (i) {
      let injectedIndex = i.replace(/{/, "").replace(/}/, "");
      injectedIndices.push(injectedIndex);
      return data[injectedIndex];
    });
    if (removeInjected && injectedIndices.size) {
      injectedIndices.forEach(idx => {
        data.splice(idx, 1);
      });
    }
    return result;
  } else if (typeof str === "string" && data instanceof Object) {
    if (Object.keys(data).length === 0) {
      return str;
    }

    return str.replace(/({([^}]+)})/g, function (i) {
      let key = i.replace(/{/, "").replace(/}/, "");
      if (typeof data[key] === "undefined") {
        return i;
      }

      let value = data[key];
      if (removeInjected) {
        delete data[key];
      }
      return value;
    });
  } else if (
    (typeof str === "string" && data instanceof Array === false) ||
    (typeof str === "string" && data instanceof Object === false)
  ) {
    return str;
  } else {
    return false;
  }
};

// 身份证正则
export const idCodeReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
